import { useNavigate } from 'react-router-dom';
import TimeFilters from '../../../../components/time-filters';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { apiService } from '../../../../services/api.service';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';
import { getColorByStatusType, getColorByCardBrandType, getRandomColor } from '../../../../types/statistics.types';
import StatisticSection from '../../../../components/statistics-section';
import StatisticsSectionBarChartType from '../../../../components/statistics-section.bar-chart-type';
import StatisticsSectionChartType from '../../../../components/statistics-section.chart-type';
import './reports-customer-page.scss';
import { colorsPalette } from '../../../../utils/colors-utils';
import { LabelValue } from '../../../../types/label-value.types';
import AppSelect from '../../../../components/form/base/app-select';
import AppSelectInput from '../../../../components/form/base/app-select-input';
import { getBaseAppPath } from '../../../../utils/path-utils';


const ReportsCustomerPage: React.FC = () => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [transactionsStatus, setTransactionsStatus] = useState();
    const [transactionsNumber, setTransactionsNumber ] = useState();
    const [cardTypes, setCardTypes] = useState();
    const [processors, setProcessors] = useState();
    const [merchants, setMerchants] = useState();
    const [cardOptions, setCardOptions] = useState<LabelValue[]>([]);
    const [selectedCard, setSelectedCard] = useState<string>();
    const [ customerOptions, setCustomerOptions ] = useState<LabelValue[]>([]);
    const [ selectedCustomer, setSelectedCustomer ] = useState<string>();
    const { user } = useSelector<AppStore, UserState>((state) => state.user)

    const baseAppPath = user ? getBaseAppPath(user) : '';

    useEffect(() => {

        apiService.getAllCustomers()
        .then((response) => {
            const transformedCustomer = response.data.map((customer: any) => ({
                label: customer || 'Unknown',
                value: customer,
            }));
            setCustomerOptions(transformedCustomer);
        })
        .catch((error) => console.error('Error fetching transactions number:', error));

        apiService
        .getAllCardTypes()
        .then((response) => {
            const transformedCards = response.data.map((card: any) => ({
                label: card || 'Unknown',
                value: card,
            }));
            setCardOptions([{
                label: 'Reset',
                value: '',
            },...transformedCards]);
        })
        .catch((error) => console.error('Error fetching merchants:', error));


        apiService.getTransactionsSummary({startDate, endDate, customerName: selectedCustomer, customerCardType: selectedCard})
        .then((response) => {setTransactionsNumber(response.data.transactionCount)})
        .catch((error) => console.error('Error fetching transactions number:', error));


        apiService.getTransactionsStatusStatistics({startDate, endDate, customerName: selectedCustomer, customerCardType: selectedCard})
            .then((response) => {
                const transformedData = response.data.map((item: any) => ({
                    color: getColorByStatusType(item.name),
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setTransactionsStatus(transformedData);
            })
            .catch((error) => console.error('Error fetching transactions status:', error));

        apiService.getCardTypeStatistics({ startDate, endDate, customerName: selectedCustomer, customerCardType: selectedCard})
            .then((response) => {
                const transformedData = response.data.map((item: any) => ({
                    color: getColorByCardBrandType(item.name),
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setCardTypes(transformedData);
            })
            .catch((error) => console.error('Error fetching card types:', error));

        apiService.getProcessorStatistics({ startDate, endDate, customerName: selectedCustomer, customerCardType: selectedCard})
            .then((response) => {
                const transformedData = response.data.map((item: any, index: number) => ({
                    color: colorsPalette[index],
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setProcessors(transformedData);
            })
            .catch((error) => console.error('Error fetching processors:', error));

        apiService.getMerchantStatistics({ startDate, endDate, customerName: selectedCustomer, customerCardType: selectedCard})
            .then((response) => {
                const transformedData = response.data.map((item: any, index: number) => ({
                    id: item.id,
                    color: colorsPalette[index],
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setMerchants(transformedData);
            })
            .catch((error) => console.error('Error fetching merchants:', error));
    }, [user?.id, startDate , endDate, selectedCustomer, selectedCard]);

 
    const handleMerchantNavigate = (id: string) => {
        navigate(`${baseAppPath}/merchants/merchant/${id}`);
    }

    const handleDataUpdate = (startDate?: string, endDate?: string) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    return (
        <div className='reports-time-page page-content'>
              <div className="page-header flex-row flex-gap-20 flex-align-start-center">
                <div className='page-title'>
                    Reports Customer          
                </div>
                <AppSelectInput
                    value={selectedCustomer}
                    options={customerOptions}
                    placeholder='Select Customer'
                    onChange={(value) => setSelectedCustomer(value) }
                />
                  <AppSelect
                    theme='filter'
                    value={selectedCard}
                    options={cardOptions}
                    placeholder='Select Card'
                    onChange={(value) => setSelectedCard(value)}
                />
            </div> 
            <div>
                <TimeFilters transactionsNo={transactionsNumber} handleFilters={handleDataUpdate}/>
            </div>
            <StatisticSection title='Status Statistics'>
                <StatisticsSectionChartType data={transactionsStatus || []} />
            </StatisticSection>
            <StatisticSection title='Card Brand Statistics'>
                <StatisticsSectionChartType data={cardTypes || []} />
            </StatisticSection>
            <StatisticSection title='Processors Statistics'>
                <StatisticsSectionBarChartType data={processors || []} />
            </StatisticSection>
            <StatisticSection title='Merchants Statistics'>
                <StatisticsSectionBarChartType data={merchants || []} onNavigate={handleMerchantNavigate} />
            </StatisticSection>
        </div>
    )
}

export default ReportsCustomerPage