import React from 'react';
import './progress-bar-chart.scss';

interface ProgressBarChartProps {
    percent: number;
    isTotal?: boolean;
}

const ProgressBarChart: React.FC<ProgressBarChartProps> = ({ percent, isTotal = false }) => {
    let labelSizeClass = '';
    if(percent < 5) {
        labelSizeClass = 'hide-gt-md'
    } else 
    if (percent < 10) {
        labelSizeClass = 'extra-small-label';
    } else if (percent < 15) {
        labelSizeClass = 'small-label';
    }

    return (
        <div className={`progress-bar-chart ${isTotal ? 'total' : ''}`}>
            <div
                className={`fill ${isTotal ? 'total' : ''}`}
                style={{ '--custom-percent': `${percent}%` } as React.CSSProperties}
            >
                <span className={`label ${labelSizeClass}`}>
                    {isTotal && labelSizeClass!= 'extra-small-label' ? 'Total Usage: ' : ''}{percent}%
                </span>
            </div>
        </div>
    );
};

export default ProgressBarChart;