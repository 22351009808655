import Section from "../../../../components/section";
import EditMerchantPartForm from "./edit-merchant.part.form";
import './edit-merchant-page.scss';
import { useParams } from "react-router-dom";



const EditMerchantPage = () => {
    const { id } = useParams();
  
    return (
        <div className="add-merchant-page page-content">
            <div className="page-header flex-row flex-gap-20 flex-align-start-center">
                <div className='page-title'>
                    Edit Merchant
                </div>
            </div>
            <Section>
                {id &&
                    <EditMerchantPartForm id={id} />
                }
            </Section>
        </div>
    )
}

export default EditMerchantPage;