import React from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import './app-datepicker.scss';

interface AppDatePickerProps {
  value?: Dayjs | null;
  onChange?: (newDate: Dayjs | null) => void;
  placeholder?: string;
  shouldDisableDate?: (date: Dayjs) => boolean;
}

const AppDatePicker: React.FC<AppDatePickerProps> = ({
  value = null,
  onChange,
  placeholder,
  shouldDisableDate,
}) => {
  return (
    <div style={{ width: '230px' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onChange={onChange}
          shouldDisableDate={shouldDisableDate}
          slotProps={{
            field: {
              clearable: true, 
              onClear: () => {
                if (onChange) {
                  onChange(null); 
                }
              },
            },
            textField: {
              placeholder: placeholder,
              sx: {
                '& .MuiInputBase-root': {
                  fontFamily: '"Poppins", sans-serif',
                  height: '50px',
                  borderRadius: '5px',
                  background: 'rgba(255, 255, 255, 1)',
                  paddingLeft: '10px',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                },
                '& .MuiOutlinedInput-input': {
                  fontFamily: '"Poppins", sans-serif',
                  textAlign: 'left',
                  fontSize: '15px',
                  fontWeight: '600',
                  color: 'rgba(36, 70, 71, 1)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSvgIcon-root': {
                  color: 'rgba(153, 153, 153, 1)',
                },
                '& .Mui-disabled': {
                  color: 'black',
                },
                '& .MuiInputBase-input::placeholder': {
                  fontFamily: '"Poppins", sans-serif',
                  color: 'rgba(0, 0, 0, 0.9)',
                  fontSize: '15px',
                  fontWeight: 700,
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default AppDatePicker;
