import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';
import { ReactComponent as MerchantIcon } from '../../../../assets/icons/merchant.svg';
import { ReactComponent as CardIcon } from '../../../../assets/icons/processor-card.svg';
import { ReactComponent as CheckApproved } from '../../../../assets/icons/check-outline.svg';
import { ReactComponent as CheckRefund } from '../../../../assets/icons/check-refund.svg';
import { ReactComponent as CheckChargeBack } from '../../../../assets/icons/check-chargedback.svg';
import { ReactComponent as Declined } from '../../../../assets/icons/declined.svg';
import './transaction-details.part.transaction-information.scss';
import { TransactionDetails } from '../../../../types/transaction.types';
import { StatusType } from '../../../../types/statistics.types';
import React, { useEffect } from 'react';
import dateService from '../../../../services/date.service';
import { apiService } from '../../../../services/api.service';


const icons = {
    approved: <CheckApproved />,
    chargedback: <CheckChargeBack />,
    refunded: <CheckRefund />,
    declined: <Declined />
}

interface TransactionDetailsPartTransactionInformationProps {
    transactionDetails: TransactionDetails[];
    errorMessage?: string;
}

const TransactionDetailsPartTransactionInformation: React.FC<TransactionDetailsPartTransactionInformationProps> = ({ transactionDetails,errorMessage }) => {



    return (
        <div className="transaction-details-part-transaction-information flex-column flex-align-start-center">
            <div className="item entity">
                <div className="primaryText flex-row flex-align-start-center flex-gap-5">
                    <UserIcon /> {transactionDetails[0].customerName}
                </div>
                <div className="secondaryText flex-row">
                    {transactionDetails[0].customerCardType} - xxxx xxxx xxxx {transactionDetails[0].customerLastFourCardDigits}
                </div>
            </div>

            {transactionDetails.map((transaction, index) => {
                return (
                <React.Fragment key={transaction.id}>
                    <div className="separator"></div>

                    <div className={`item action ${transaction?.status?.toLowerCase()}`}>
                        <div>
                            <div className="primaryText flex-row flex-align-start-center flex-gap-5">
                                <CardIcon /> {transaction.processorName}
                            </div>
                            <div className="secondaryText flex-row">
                                {transaction.processorTransactionId}
                            </div>
                        </div>
                        {transaction.status !== StatusType.ERROR &&
                            icons[transaction?.status?.toLowerCase() as keyof typeof icons]}
                        {(transaction.status === StatusType.ERROR || transaction.status === StatusType.DECLINED)  && (
                            <div className={`info-container flex-column flex-gap-10`}>
                                <div className={`title ${transaction?.status?.toLowerCase()}`}>{ transaction.status === StatusType.ERROR ? 'Technical error' :  'Decline reason' }</div>
                                <div className="description">
                                    {transaction.internalErrorMessage}{errorMessage}
                                </div>
                            </div>
                        )}

                        <div className={`action-date flex-column flex-gap-5  ${transaction.status == StatusType.ERROR ? 'error' : ''}`}>
                            <span className={`title ${transaction.status.toLowerCase()}`}>
                                {transaction.status === StatusType.APPROVED ||
                                    transaction.status === StatusType.REFUNDED ||
                                    transaction.status === StatusType.CHARGEDBACK
                                    ? 'Successfully executed'
                                    : transaction.status === StatusType.ERROR
                                        ? 'Unexecuted'
                                        : transaction.status === StatusType.DECLINED
                                            ? 'Failed execution'
                                            : transaction.status === StatusType.PENDING
                                                ? transaction.intermediateStatus
                                                : ''}
                            </span>
                            <span className='date'>
                                {transaction.status !== StatusType.ERROR ? dateService.formatDateForTable(transaction.date) : 'Technical error X'}
                            </span>
                        </div>
                    </div>


                    {transaction.method == 'DEPOSIT' ? (
                        transaction.merchantName && <>
                        <div className="separator"></div>

                        <div className="item entity">
                            <div className="primaryText flex-row flex-align-start-center flex-gap-5">
                                <MerchantIcon /> {transaction.merchantName}
                            </div>
                            <div className="secondaryText flex-row">
                                {transaction.merchantId}
                            </div>
                        </div>
                        </>
                    ) : (
                        <>
                        <div className="separator"></div>
                        <div className="item entity">
                            <div className="primaryText flex-row flex-align-start-center flex-gap-5">
                                <UserIcon /> {transactionDetails[0].customerName}
                            </div>
                            <div className="secondaryText flex-row">
                                {transactionDetails[0].customerCardType} - xxxx xxxx xxxx {transactionDetails[0].customerLastFourCardDigits}
                            </div>
                        </div>
                        </>
                    )}
                </React.Fragment>
            )})}
        </div>
    );
};

export default TransactionDetailsPartTransactionInformation;
