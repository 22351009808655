import { RouteObject } from "react-router-dom";
import TransactionsPage from "./transactions-page";
import TransactionDetailsPage from "./transaction-details/transaction-details-page";
import DisputesPage from "./disputes/disputes-page";
import ChargebacksPage from "./chargebacks/chargebacks-page";
import RefundsPage from "./refunds/refunds-page";

const transactionsRouter: RouteObject[] = [
    {
        path: '',
        element: <TransactionsPage />
    },
    {
        path: 'disputes',
        element: <DisputesPage/>
    },
    {
        path: 'chargebacks',
        element: <ChargebacksPage/>
    },
    {
        path: 'refunds',
        element: <RefundsPage/>
    },
    {
        path: 'transaction/:id',
        element: <TransactionDetailsPage/>
    }
]

export default transactionsRouter;