import React from "react";
import { ReactComponent as RefundIcon } from '../assets/icons/refund.svg';
import "./app-modal-transaction.scss";
import { getColorByNextStatusType, getColorByStatusType, StatusType } from "../types/statistics.types";

interface AppModalProps {
  text: string;
  id: string;
  confirmLabel: string;
  cancelLabel: string;
  confirmAction: (id: string, status: StatusType) => Promise<void>;
  cancelAction: () => void;
  isVisible: boolean;
  status: StatusType;
}

const AppModal: React.FC<AppModalProps> = ({
  text,
  id,
  confirmLabel,
  cancelLabel,
  confirmAction,
  cancelAction,
  isVisible,
  status
}) => {
  if (!isVisible) return null;
  
  const statusColor = getColorByNextStatusType(status);

  return (
    <div className="app-modal-transaction-overlay">
      <div className="app-modal">
        <div className="modal-icon">
          <RefundIcon color={statusColor} />
        </div>
        <div className="modal-content">
          <p className="modal-text">
            {text}
            <span style={{ color: statusColor }}>{status}</span>?
          </p>
          <p className="modal-id">Transaction {id}</p>
        </div>
        <div className="modal-actions">
          <button
            className="btn btn-cancel"
            onClick={cancelAction}>
            {cancelLabel}
          </button>
          <button
            className="btn btn-confirm"
            onClick={() => confirmAction(id, status)}
            style={{ backgroundColor: statusColor }}
          >
            {confirmLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppModal;
