import { Statistic } from "../types/statistics.types";
import LegendItem from "./legend-item";
import PolarAreaChart from "./polar-area-chart";

interface StatisticsSectionChartTypeProps {
    data: Statistic[];
}

const StatisticsSectionChartType: React.FC<StatisticsSectionChartTypeProps> = ({ data }) => {

    const colors = data.map((statistic) => statistic.color);
    const quantitativePercentValues = data.map((statistic) =>
        statistic.quantitativePercent.toFixed(1).toString()
    );
    const qualitativePercentValues = data.map((statistic) =>
        statistic.qualitativePercent.toFixed(1).toString()
    );
    return (
        <div className='statistic-section-chart-type flex-row flex-align-start-center flex-gap-10'>
            <PolarAreaChart
                labels={quantitativePercentValues}
                backgroundColors={colors}
                centerText='Number of Transactions'
            />
            <PolarAreaChart
                labels={qualitativePercentValues}
                backgroundColors={colors}
                centerText='Value of Transactions'
            />
            <div className='flex-row flex-gap-20 width-100 flex-wrap'>
                {data.map(statistic =>
                    <LegendItem
                        id={statistic.id}
                        color={statistic.color}
                        name={statistic.name}
                        quantitativePercent={statistic.quantitativePercent}
                        quantitativeValue={statistic.quantitativeValue}
                        qualitativePercent={statistic.qualitativePercent}
                        qualitativeValue={statistic.qualitativeValue}

                    />
                )}

            </div>

        </div>
    )
}

export default StatisticsSectionChartType;