import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from '../models';
import { localStorageService, StorageKey } from '../services/local-storage.service';

export interface UserState {
  user?: UserModel;
}

const initialState: UserState = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state: UserState, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    removeUser: (state: UserState) => {
      state.user = undefined;
      localStorageService.removeItem(StorageKey.ACCESS_TOKEN);
      localStorageService.removeItem(StorageKey.REFRESH_TOKEN);
    },
  }
});

export const userReducer = userSlice.reducer;
export const userSliceActions = {
  saveUser: userSlice.actions.saveUser,
  removeUser: userSlice.actions.removeUser,
};