import { ReactComponent as CheckOutlineIcon } from '../../../assets/icons/check-outline.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-outline.svg';
import './transactions-page.part.data-item.scss';

interface TransactionsPartDataItemProps {
    theme: 'success' | 'error';
    total: number;
    noTransactions: number;
    rate: number;
}

const TransactionsPartDataItem: React.FC<TransactionsPartDataItemProps> = ({
    theme,
    total,
    noTransactions,
    rate,
}) => {
    return (
        <div
            className={`transactions-part-data-item ${theme} flex-column  flex-gap-5 flex-1`}
        >
            <div className="icon">
                {theme === 'success' ? <CheckOutlineIcon /> : <ErrorIcon />}
            </div>
                <div className={`description ${theme}`}>
                    {theme === 'success' ? 'Total Successful Amount:' : 'Total Failed Amount:'}{' '}
                    <span className="value">
                        ${total?.toLocaleString(undefined, { minimumFractionDigits: 2 }) || 0}
                    </span>
                </div>
                <div className={`description ${theme}`}>
                    {theme === 'success'
                        ? 'Number of Successful Transactions:'
                        : 'Number of Failed Transactions:'}{' '}
                    <span className="value">{noTransactions}</span>
                </div>
                <div className={`description ${theme}`}>
                    {theme === 'success'
                        ? 'Transaction Success Rate:'
                        : 'Transaction Fail Rate:'}{' '}
                    <span className="value">
                        {rate?.toLocaleString(undefined, { minimumFractionDigits: 2 }) || 0}% 
                    </span>
                </div>
        </div>
    );
};

export default TransactionsPartDataItem;
