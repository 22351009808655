import { UseFormReturn } from "react-hook-form";
import { EditMerchantForm, CommissionValueType } from "./edit-merchant.part.form";
import AppFormInput from "../../../../components/form/app-form-input";
import AppFormRadioGroup, { RadioOption } from "../../../../components/form/app-form-radio-group";
import './edit-merchant.part.form.commission-field.scss';

interface AddMerchantPartFormCommissionFieldProps {
    form: UseFormReturn<EditMerchantForm>;
    commissionName: `midList.${number}.midsDetails.${number}.commissions.${number}`;
    label: string;
    secondaryLabel: string;
    removeCommission: () => void;
}


const AddMerchantPartFormCommissionField: React.FC<AddMerchantPartFormCommissionFieldProps> = ({
    form,
    commissionName,
    label,
    secondaryLabel,
    removeCommission,
}) => {
    const radioOptions: RadioOption<string>[] = [
        { value: CommissionValueType.PERCENT, text: 'Percent' },
        { value: CommissionValueType.FIXED, text: 'Fixed $' },
    ];

    return (
        <div className="commission-item flex-column flex-gap-15">
            <div className="label">{label} <span>({secondaryLabel})</span></div>
            <div className="flex-row flex-gap-20 flex-align-space-between-center">
                <div className="commission-field-value">
                    <AppFormInput
                        control={form.control}
                        name={`${commissionName}.value` as const}
                        type="number"
                        placeholder="Value"
                        validations={{
                            required: true, min: {
                                value: 0,
                                message: 'Commission value cannot be negative',
                            },
                        }}
                        min={0}
                    />
                </div>
                <div className="commission-field-type">
                    <AppFormRadioGroup
                        control={form.control}
                        name={`${commissionName}.valueType` as const}
                        options={radioOptions}
                        validations={{ required: true }}
                    />
                </div>

            </div>
        </div>
    );
};

export default AddMerchantPartFormCommissionField;
