import ProgressBarChart from "../../../../components/progress-bart-chart";
import TableContent from "../../../../components/table-content";
import { mockMidsTable } from "../../../../mock/table";
import { ReactComponent as MerchantIcon } from '../../../../assets/icons/merchant.svg';
import { ReactComponent as ProcessorCardIcon } from '../../../../assets/icons/processor-card.svg';
import { ReactComponent as PDFIcon } from '../../../../assets/icons/pdf.svg';
import { ReactComponent as ExcelIcon } from '../../../../assets/icons/excel.svg';
import Button from "../../../../components/button";
import './mids-page.scss';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiService } from "../../../../services/api.service";
import { useSelector } from "react-redux";
import { AppStore } from "../../../../store/store";
import { UserState } from "../../../../store/user.slice";
import { errorService } from "../../../../services/error.service";
import { getBaseAppPath } from "../../../../utils/path-utils";


const MidsPage: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [ mids, setMids ] = useState<[]>();
    const [ totalUsage, setTotalUsage ] = useState();
    const { user } = useSelector<AppStore, UserState>((state) => state.user)
    const baseAppPath = user ? getBaseAppPath(user) : '';

    const columnsMids = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ value }: { value: string }) => (
                <span>{value}</span>
            ),
        },
        {
            Header: 'Cap',
            accessor: 'limit',
            Cell: ({ value }: { value: any }) => (
                <span className="gradient-accent-text">{value}$</span>
            )
        },
        {
            Header: 'Merchant',
            accessor: 'merchantName',
            Cell: ({ value }: { value: any }) => (
                <div className="flex-row flex-align-start-center flex-gap-5">
                    <MerchantIcon />
                    <span>{value}</span>
                </div>
            )
        },
        {
            Header: 'Processor',
            accessor: 'processorName',
            Cell: ({ value }: { value: any }) => (
                <div  className="flex-row flex-align-start-center flex-gap-5">
                    <ProcessorCardIcon/>
                    <span>{value}</span>
                </div>
                
            )
        },
        {
            Header: 'Usage',
            accessor: 'percentUsage',
            Cell: ({ value }: { value: any }) => (
                <ProgressBarChart percent={value} />
            )
        },
        {
            Header: '',
            accessor: ' ',
            Cell: ({ value, row }: { value: any, row: any }) => (
                <span
                 className="link-text"
                 onClick={() => {navigate(`${baseAppPath}/merchants/mid-transactions/${row.original.id}`)}}
                 >View Transactions</span>
            ),
        },

    ];

    useEffect(() => {
        const getMids = async () => {
            if(!id)
                return
            const response = await apiService.getMidsByMerchantId(id);
            return response.data;
        }

        getMids().then((data) => {
                setTotalUsage(data?.totalUsage)
                setMids(data?.merchantsMids)
        }).catch(error => console.log(error)
        )
    }, [id, user])


    const handleDownloadExcel = async () => {
        try {
            const response = await apiService.generateExcel({merchantId: id});
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'transactions.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            errorService.showApiError(error);
            console.error('Failed to download Excel file:', error);
        }
    };

    return (
        <div className="mids-page page-content">
            <div className="page-header flex-row flex-gap-10 flex-align-start-end">
                <div className='page-title'>
                    MIDs
                </div>
                <div className="page-subtitle">{mids?.length} MIDs</div>
            </div>
            <div className='flex-row flex-gap-20 width-100'>
                <ProgressBarChart percent={totalUsage || 0} isTotal/>
                <Button
                theme='download'
                label='Download Excel'
                onClick={() => handleDownloadExcel()}
                icon={ExcelIcon}
                />
                {/* <Button
                theme='download'
                label='Download PDF'
                icon={PDFIcon}
                /> */}
            </div>
            <div className='width-100'>
                <TableContent columns={columnsMids} data={mids || []}></TableContent>
            </div>
        </div>
    );
}

export default MidsPage;