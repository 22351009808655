import { LoginResponse } from "./auth.service-types";
import { jwtDecode } from "jwt-decode";
import { LoginJwtPayload } from "./key-cloak-api.service.types";
import { keyCloakApiService } from "./key-cloak-api.service";
import { localStorageService, StorageKey } from "./local-storage.service";
import { Dispatch } from "react";
import { userSliceActions } from "../store/user.slice";

class AuthService {
  isRefreshInProgress?: Promise<void>;
  async login(
    data: { username: string; password: string },
  ): Promise<LoginResponse> {
    const res = await keyCloakApiService.login(data);

    localStorageService.setItem(StorageKey.ACCESS_TOKEN, res.data.access_token);
    localStorageService.setItem(StorageKey.REFRESH_TOKEN, res.data.refresh_token);
    return this.decodeLoginJwt(res.data.access_token);
  }


  async logout(dispatch: Dispatch<any>) {
     localStorageService.removeItem(StorageKey.ACCESS_TOKEN);
     localStorageService.removeItem(StorageKey.REFRESH_TOKEN);
    dispatch(userSliceActions.removeUser());
  }

  async getAuthenticatedUser(): Promise<LoginResponse | null> {
    const accessToken = localStorageService.getItem(StorageKey.ACCESS_TOKEN);
    if (accessToken) {
      return this.decodeLoginJwt(accessToken);
    }

    return null;
  }

  private decodeLoginJwt(accessToken: string): LoginResponse {
    const jwtPayload = jwtDecode<LoginJwtPayload>(accessToken);
    return {
      firstName: jwtPayload.given_name,
      lastName: jwtPayload.family_name,
      email: jwtPayload.email,
      verified: jwtPayload.email_verified,
      userId: jwtPayload.sub,
      roles: jwtPayload.realm_access.roles,
    };
  }

  async refreshLogin(refreshToken: string) {
    if (this.isRefreshInProgress) {
      return this.isRefreshInProgress;
    }

    this.isRefreshInProgress = new Promise(async (resolve, reject) => {
      try {
        const res = await keyCloakApiService.refreshToken(refreshToken);

        localStorageService.setItem(StorageKey.ACCESS_TOKEN, res.data.access_token);
        localStorageService.setItem(StorageKey.REFRESH_TOKEN, res.data.refresh_token);
      } catch (error) {
        reject(error);
      }
      finally {
        resolve();
      }
    });

    await this.isRefreshInProgress.catch((e) => {throw e});
    this.isRefreshInProgress = undefined;
  }

  // async refreshLogin(refreshToken: string) {
  //   if (this.isRefreshInProgress) {
  //     return this.isRefreshInProgress;
  //   }
  //   const res = await keyCloakApiService.refreshToken(refreshToken);
  //   localStorageService.setItem(StorageKey.ACCESS_TOKEN, res.data.access_token);
  //   localStorageService.setItem(StorageKey.REFRESH_TOKEN, res.data.refresh_token);
  // }

}

const authService = new AuthService();
export { authService };