import { Navigate, RouteObject } from "react-router-dom";
import DashboardPage from "./dashboard/dashboard-page";
import merchantsRouter from "./merchants/merchants-router";
import processorsRouter from "./processors/processors-router";
import transactionsRouter from "./transactions/transactions-router";
import dashboardRouter from "./dashboard/dashboard-router";

const privateRouter: RouteObject[] = [
    {
        path: '',
        element: <Navigate to="dashboard" replace />, 
    },
    {
        path: 'dashboard',
        children: dashboardRouter
    },
    {
        path: 'transactions',
        children: transactionsRouter
    },
    {
        path: 'merchants',
        children: merchantsRouter
    },
    {
        path: 'processors',
        children: processorsRouter
    },
   
]

export default privateRouter;