import { RouteObject } from "react-router-dom";
import MerchantsPage from "./merchants-page";
import AddMerchantPage from "./add-merchant/add-merchant-page";
import MidsPage from "./mids/mids-page";
import MidTransactionsPage from "./mid-transactions/mid-transactions-page";
import EditMerchantPage from "./edit-merchant/edit-merchant-page";

const merchantsRouter: RouteObject[] = [
    {
        path: '',
        element: <MerchantsPage />
    },
    {
        path: 'add-merchant',
        element: <AddMerchantPage/>
    },
    {
        path: 'edit-merchant/:id',
        element: <EditMerchantPage/>
    },
    {
        path: 'merchant/:id',
        element: <MidsPage/>
    },
    {
        path: 'mid-transactions/:id',
        element: <MidTransactionsPage/>
    }
]

export default merchantsRouter;