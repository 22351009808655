import React, { useEffect, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import AppFormInput from '../../../../components/form/app-form-input';
import AppFormSelect from '../../../../components/form/app-form-select';
import { EditMerchantForm, CommissionType, CommissionValueType } from './edit-merchant.part.form';
import AddElementButton from '../../../../components/form/add-element-button';
import { ReactComponent as RemoveOutlineIcon } from '../../../../assets/icons/remove-outline.svg';
import './edit-merchant.part.form.add-processor.scss'
import { ProcessorType } from '../../../../types/processor.types';
import { apiService } from '../../../../services/api.service';
import AddMerchantPartFormCommissionField from './edit-merchant.part.form.commission-field';
import Button from '../../../../components/button';
import MIDField from './edit-merchant.part.mid-field';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';




interface ProcessorFieldProps {
    form: UseFormReturn<EditMerchantForm>;
    processorIndex: number;
    removeProcessor: (index: number) => void;
}

interface ProcessorData {
    id: number,
    processorType: ProcessorType,
    processorName: string;
}

const ProcessorField: React.FC<ProcessorFieldProps> = ({
    form,
    processorIndex,
    removeProcessor,
}) => {
    const [processors, setProcessors] = useState<ProcessorData[]>();
    const { fields: midFields, append: addMID, remove: removeMID } = useFieldArray({
        control: form.control,
        name: `midList.${processorIndex}.midsDetails` as const,
    });
    const { user } = useSelector<AppStore, UserState>((state) => state.user);
    const isProcessorSelected = !!form.watch(`midList.${processorIndex}.processorId`);
    const isProcessorNew = !!form.watch(`midList.${processorIndex}.new`)
    const {
        fields: commissionFields,
        append: addTransactionCommission,
        remove: removeTransactionCommission,
    } = useFieldArray({
        control: form.control,
        name: `midList.${processorIndex}.midsDetails.${midFields.length - 1}.commissions` as const,
    });


    useEffect(() => {
        const getProcessors = async () => {
            try {
                const response = await apiService.getAllProcessors();
                return response.data;
            } catch (e) {
                return []
            }
        }
        getProcessors().then((data) => {
            setProcessors(data)
        })
    }, [user])
    const processorsOptions = processors && processors?.map((processor) => {
        return {
            label: `${processor.processorName} (${processor.processorType})`,
            value: processor.id
        }
    })



    return (
        <>
            <div className="add-processor-form-container flex-row flex-gap-20 width-100">
                <div className='flex-1-form'>
                    <div className='flex-row flex-align-start-start flex-gap-5'>
                        <AppFormSelect
                            control={form.control}
                            name={`midList.${processorIndex}.processorId` as const}
                            options={processorsOptions || []}
                            placeholder={`Processor ${processorIndex + 1}`}
                            validations={{ required: true }}
                        />
                        {processorIndex != 0 && isProcessorNew &&
                            <RemoveOutlineIcon
                                className="remove-icon"
                                onClick={() => removeProcessor(processorIndex)}
                            />
                        }
                    </div>
                </div>
                {!isProcessorSelected && (
                    <div className="flex-2-form"></div>
                )}
                {isProcessorSelected && (
                    <div className="flex-column flex-gap-40 flex-2-form">
                        {midFields.map((mid, midIndex) => (
                            <MIDField
                                key={mid.id}
                                form={form}
                                processorIndex={processorIndex}
                                midIndex={midIndex}
                                removeMID={removeMID}
                                mid={mid}
                            />
                        ))}

                        <AddElementButton
                            text="Add MID"
                            onClick={() => addMID({
                                midCode: '',
                                midName: '',
                                valid: true,
                                new: true,
                                commissions: Object.values(CommissionType).map((type) => ({
                                    value: 0,
                                    valueType: CommissionValueType.PERCENT,
                                    type,
                                    anyValue: false,
                                })),
                            })}
                        />
                    </div>
                )}
            </div >

        </>
    );
};

export default ProcessorField;
