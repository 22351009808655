import React, { useEffect, useState } from 'react';
import './bar-chart.scss';

interface BarSegment {
    percent: number;
    color: string;
}

interface StackedBarChartProps {
    segments: BarSegment[];
    label?: string;
}

const StackedBarChart: React.FC<StackedBarChartProps> = ({ segments, label }) => {
    const [animatedSegments, setAnimatedSegments] = useState<BarSegment[]>(
        segments.map((segment) => ({ ...segment, percent: 0 }))
    );

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimatedSegments(segments);
        }, 100);
        return () => clearTimeout(timeout);
    }, [segments]);

    return (
        <div className="bar-chart">
            <div className="bar-row">
                {label && <span className="bar-label">{label}</span>}
                
                <div className="bar">
                    {animatedSegments.map((segment, index) => (
                        <div
                            key={index}
                            className="bar-segment"
                            style={{
                                width: `${segment.percent}%`,
                                backgroundColor: segment.color,
                            }}
                            data-tooltip={`${segment.percent}%`}
                        >
                            {segment.percent > 5 && (
                                <span className="segment-label">
                                    {`${segment.percent.toFixed(2)}%`}
                                </span>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default StackedBarChart;
