import React from 'react';
import { PolarArea } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    RadialLinearScale,
    Tooltip,
    Legend,
    ChartData,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, RadialLinearScale, Tooltip, Legend, ChartDataLabels);



interface PolarAreaChartProps {
    labels: string[];
    backgroundColors: string[]; 
    startingValue?: number; 
    centerText: string;
}

const PolarAreaChart: React.FC<PolarAreaChartProps> = ({
    labels,
    backgroundColors,
    startingValue = 40, 
    centerText
}) => {
    const dataValues = labels.map((_, index) => startingValue - index+2).filter((value) => value > 0);

  

    const data: ChartData<'polarArea'> = {
        labels, 
        datasets: [
            {
                data: dataValues, 
                backgroundColor: backgroundColors, 
                borderWidth: 0, 
            },
        ],
    };
    
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false, 
            },
            datalabels: {
                display: true, 
                color: '#fff', 
                font: {
                    size: 13, 
                    weight: 'bold' as const, 
                },
                formatter: (value: any, context: any) => {
                    const label = context.chart.data.labels?.[context.dataIndex];
                    return value > 0 ? `${label}%` : ''; 
                },
                anchor: 'end' as const, 
                align: 'start' as const, 
                offset: 7 , 
            },
        },
        scales: {
            r: {
                ticks: {
                    display: false, 
                },
                grid: {
                    display: false, 
                },
            },
        },
    };
    const centerTextPlugin = {
        id: 'centerText',
        afterDraw(chart: any) {
            const { width, height } = chart;
            const ctx = chart.ctx;
    
            const centerX = width / 2;
            const centerY = height / 2;
            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX, centerY, 48, 0, 2 * Math.PI); 
            ctx.fillStyle = '#fff';
            ctx.fill();
            ctx.restore();
    
            const text = centerText;
            const words = text.split(' '); 
            const lineHeight = 18; 
            let line = '';
            const lines: string[] = [];
            const maxWidth = 100; 
    
            words.forEach((word) => {
                const testLine = line + word + ' ';
                const metrics = ctx.measureText(testLine);
                const testWidth = metrics.width;
                if (testWidth > maxWidth && line !== '') {
                    lines.push(line);
                    line = word + ' ';
                } else {
                    line = testLine;
                }
            });
            lines.push(line); 
    
            ctx.font = 'bold 13px Arial';
            ctx.fillStyle = '#000';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
    
            lines.forEach((line, index) => {
                ctx.fillText(line.trim(), centerX, centerY - ((lines.length - 1) / 2) * lineHeight + index * lineHeight);
            });
        },
    };

    return (
        <div
            style={{
                position: 'relative',
                width: '240px',
            }}
        >
            <PolarArea data={data} options={options} plugins={[centerTextPlugin]} />
        </div>
    );
};

export default PolarAreaChart;
