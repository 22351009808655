import { Navigate } from "react-router-dom";
import AuthLayout from "./auth-page.layout";
import { useSelector } from "react-redux";
import { AppStore } from "../../store/store";
import { UserState } from "../../store/user.slice";
import { getBaseAppPath } from "../../utils/path-utils";

const AuthGuard = () => {
    const { user } = useSelector<AppStore, UserState>((state) => state.user )

    const baseAppPath = user ? getBaseAppPath(user) : '';

     return user?.id ? <Navigate to={baseAppPath} /> : <AuthLayout />;
}

export default AuthGuard; 