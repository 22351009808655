import { PropsWithChildren, useState } from "react";
import './section.scss';

interface SectionProps extends PropsWithChildren {
}

const Section: React.FC<SectionProps> = ({ children }) => {

    return (
        <div className="section-container">
            {children}
        </div>
    )
}

export default Section;