import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { authService } from './services/auth.service';
import { userSliceActions } from './store/user.slice';
import { useDispatch } from 'react-redux';
import { apiService } from './services/api.service';
import { Toast } from 'primereact/toast';
import { errorService } from './services/error.service';

const AppInit: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const toastRef = useRef<Toast>(null);

  useEffect(() => {
    if (toastRef.current) {
      errorService.setToastRef(toastRef.current);
    }

    
    apiService.attachDispatchToAxiosApi(dispatch);
  

    authService.getAuthenticatedUser()
      .then(async user => {
        if (user) {
          try {
            const response = await apiService.getUserDetails(user.userId)

            dispatch(userSliceActions.saveUser({...user,
              ...response.data,
            }
            ))
          } catch (e) {
            console.log(e);
            
          }

        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, []);

  return <div>
    <Toast ref={toastRef} position="bottom-right"/>
    {children}
  </div>;
}

export default AppInit;