import { ProcessorType } from "./processor.types";

export interface Statistic {
    id: string;
    color: string;
    name: string;
    quantitativePercent: number;
    quantitativeValue: number;
    qualitativePercent: number;
    qualitativeValue: number;
}


export enum StatusType {
    APPROVED = 'APPROVED',
    CHARGEDBACK = 'CHARGEDBACK',
    REFUNDED = 'REFUNDED',
    DECLINED = 'DECLINED',
    PENDING = 'PENDING',
    ERROR = 'ERROR',
}

export enum IntermediateStatusType {
    DONE = 'DONE',
    PENDING_APPROVE = 'PENDING_APPROVE',
    PENDING_REFUND = 'PENDING_REFUND',
    PENDING_CHARGEBACK = 'PENDING_CHARGEBACK',
}




export enum CardBrandType {
    VISA = 'Visa',
    MASTERCARD = 'MasterCard',
    AMERICA_EXPRESS = 'American Express'
}

export const getColorByStatusType = (type: StatusType): string => {
    const statusData: Partial<Record<StatusType, string>> = {
        APPROVED: '#00B69B',
        CHARGEDBACK: '#32ADE6',
        REFUNDED: '#6A5CA8',
        DECLINED: '#FF9500',
        PENDING: '#5C4033',
        ERROR: '#EF1E1A',
    };

    return statusData[type] || '#000000';
};

export const getColorByNextStatusType = (type: any): string => {
    const statusData: any = {
        APPROVED: '#00B69B',
        CHARGEDBACK: '#32ADE6',
        REFUNDED: '#6A5CA8',
        DECLINED: '#FF9500',
        PENDING: '#5C4033',
        ERROR: '#EF1E1A',
    };

    return statusData[type] || '#000000';
};

export const getColorByCardBrandType = (type: CardBrandType): string => {
    const cardBrandData: Record<CardBrandType, string> = {
        Visa: '#FCBE2D',
        MasterCard: '#FF9500',
        "American Express": '#32ADE6',
    };

    return cardBrandData[type] || '#000000';
};





export const getRandomColor = (index: number): string => {
    return colorsArray[index];
};

const colorsArray = [
    '#00B69B', // Teal
    '#32ADE6', // Light Blue
    '#6A5CA8', // Purple
    '#FF9500', // Orange
    '#EF1E1A', // Red
    '#FFD700', // Gold
    '#A3CB38', // Lime Green
    '#009688', // Turquoise
    '#9C27B0', // Deep Purple
    '#795548', // Brown
    '#8E44AD', // Violet
    '#34495E', // Dark Blue Gray
    '#F1C40F', // Yellow
    '#E67E22', // Pumpkin
    '#2980B9', // Strong Blue
    '#27AE60', // Forest Green
    '#C0392B', // Strong Red
    '#2ECC71', // Emerald
    '#E74C3C', // Coral
    '#1ABC9C', // Strong Teal
    '#2C3E50'  // Midnight Blue
];


// TODO move functions to service / helper


