import './info-disabled.scss';

interface InfoDisabledProps {
    label: string;
    value: string | number;
}

const InfoDisabled: React.FC<InfoDisabledProps> = ({label , value}) => {
    return (
    <div className="info-disabled flex-column flex-align-center-start flex-1-form ">
            <div className='label'>{label}</div>
            <div className='value'>{value}</div>
    </div>
    )
} 

export default InfoDisabled;