import Section from "../../../../components/section";
import AddMerchantPartForm from "./add-merchant.part.form";
import './add-merchant-page.scss';


const AddMerchantPage = () => {
    return (
        <div className="add-merchant-page page-content">
            <div className="page-header flex-row flex-gap-20 flex-align-start-center">
                <div className='page-title'>
                    Add Merchant
                </div>
            </div>
            <Section>
                <AddMerchantPartForm/>
            </Section>
        </div>
    )
}

export default AddMerchantPage;