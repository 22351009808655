import { RouteObject } from "react-router-dom";
import ProcessorsPage from "./processors-page";
import AddProcessorPage from "./add-processor/add-processor-page";

const processorsRouter: RouteObject[] = [
    {
        path: '',
        element: <ProcessorsPage />
    },
    // {
    //     path: 'add-processor',
    //     element: <AddProcessorPage/>
    // }
]

export default processorsRouter;