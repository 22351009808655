import React from 'react';
import { ReactComponent as AddOutlineicon } from '../../assets/icons/add-outline.svg';
import './add-element-button.scss';

interface AddElementButtonProps {
    text: string;
    onClick: () => void; 
}

const AddElementButton: React.FC<AddElementButtonProps> = ({ text, onClick }) => {
    return (
        <div className="add-element-button" onClick={onClick}>
            <span className="plus-icon"><AddOutlineicon/></span>
            <span className="button-text">{text}</span>
        </div>
    );
};

export default AddElementButton;
