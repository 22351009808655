import { PropsWithChildren } from "react";
import './card-legend-item.scss';
import { ForwardedProps } from "../types/component-props.types";



interface CardLegendItemProps extends PropsWithChildren {
    style?: ForwardedProps
}



const CardLegendItem: React.FC<CardLegendItemProps> = ({ children, style }) => {

    return (
        <div className={`card-legend-item ${style?.className}`}>
            {children}
        </div>
    )
}

export default CardLegendItem;