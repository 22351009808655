import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        welcome: "Welcome to our application!",
        description: "This is an example of internationalization."
      }
    },
    fr: {
      translation: {
        welcome: "Bienvenue dans notre application!",
        description: "Ceci est un exemple d'internationalisation."
      }
    }
  },
  lng: 'en', 
  fallbackLng: 'en', 
  interpolation: {
    escapeValue: false 
  }
});

export default i18n;
