import StatisticSection from '../../../components/statistics-section';
import StatisticsSectionBarChartType from '../../../components/statistics-section.bar-chart-type';
import StatisticsSectionChartType from '../../../components/statistics-section.chart-type';
import { useEffect, useState } from 'react';
import { apiService } from '../../../services/api.service';
import { getColorByStatusType, getColorByCardBrandType, getRandomColor } from '../../../types/statistics.types';
import { useNavigate } from 'react-router-dom';
import DashboardPagePartSummary from './dashboard-page.part.summary';
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/user.slice';
import { AppStore } from '../../../store/store';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import { ReactComponent as MerchantIcon } from '../../../assets/icons/merchant.svg';
import { ReactComponent as CardIcon } from '../../../assets/icons/processor-card.svg';
import './dashboard-page.scss';
import TableContent from '../../../components/table-content';
import dateService from '../../../services/date.service';
import { colorsPalette } from '../../../utils/colors-utils';
import { TransactionSummary } from '../../../types/transaction.types';
import { formatAmount } from '../../../utils/number-utils';
import { getBaseAppPath } from '../../../utils/path-utils';


const DashboardPage = () => {
    const navigate = useNavigate();
    const [recentTransactions, setRecentTranscations] = useState();
    const [transactionsSummary, setTransactionsSummary] = useState<TransactionSummary>();
    const [transactionsStatus, setTransactionsStatus] = useState();
    const [cardTypes, setCardTypes] = useState([]);
    const [processors, setProcessors] = useState();
    const [merchants, setMerchants] = useState();
    const { user } = useSelector<AppStore, UserState>((state) => state.user)

    const isMerchant = user?.roles?.includes("merchant") ?? false;

    const baseAppPath = user ? getBaseAppPath(user) : '';

    

    useEffect(() => {


        apiService.getTransactionsStatusStatistics({  })
            .then((response) => {
                const transformedData = response.data.map((item: any, index: number) => ({
                    color: getColorByStatusType(item.name),
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setTransactionsStatus(transformedData);
            })
            .catch((error) => console.error('Error fetching transactions status:', error));

        apiService.getCardTypeStatistics({  })
            .then((response) => {
                const transformedData = response.data.map((item: any) => ({
                    color: getColorByCardBrandType(item.name),
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setCardTypes(transformedData);
            })
            .catch((error) => console.error('Error fetching card types:', error));

        apiService.getProcessorStatistics({  })
            .then((response) => {
                const transformedData = response.data.map((item: any, index: number) => ({
                    color: colorsPalette[index],
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setProcessors(transformedData);
            })
            .catch((error) => console.error('Error fetching processors:', error));

        if (!isMerchant && user) {
            apiService.getMerchantStatistics({})
                .then((response) => {
                    const transformedData = response.data.map((item: any, index: number) => ({
                        id: item.id,
                        color: colorsPalette[index],
                        name: item.name,
                        quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                        quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                        qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                        qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                    }));
                    setMerchants(transformedData);
                })
                .catch((error) => console.error('Error fetching merchants:', error));
        }
        apiService.getTransactionsSummary({  })
            .then((response) => {
                setTransactionsSummary(response.data);
            })
            .catch((error) => console.error('Error fetching transactions summary:', error));

        apiService.getRecentTransactions()
            .then((response) => {
                setRecentTranscations(response.data);
            })
            .catch((error) => console.error('Error fetching transactions summary:', error));
    }, [user]);

    const columnsRecentTransactions = [
        {
            Header: 'Trace ID',
            accessor: 'id',
            Cell: ({ value, row }: { value: string, row: any }) => (
                <div>
                    <span
                        className="id-text"
                        onClick={() => navigate(`${baseAppPath}/transactions/transaction/${row.original.id}`)}
                    >{value}</span>
                </div>
            ),
        },
        {
            Header: 'Customer',
            accessor: 'customerName',
            Cell: ({ value, row }: { value: string; row: any }) => (
                <div className="flex-column flex-gap-5">
                    <div className="flex-row flex-gap-5 flex-align-start-center">
                        <UserIcon />
                        <span>{value}</span>
                    </div>
                    <div className="secondary-text accent-gradient-text">
                        {row.original.customerCardType} xxxx xxxx xxxx {row.original.customerLastFourCardDigits}
                    </div>
                </div>
            ),
        },
        {
            Header: 'Merchant',
            accessor: 'merchantName',
            Cell: ({ value, row }: { value: string; row: any }) => (
                <div className="flex-column flex-gap-5">
                    <div className="flex-row flex-gap-5 flex-align-start-center">
                        <MerchantIcon />
                        <span>{value}</span>
                    </div>
                    <div className="secondary-text accent-gradient-text">{row.original.merchantId}</div>
                </div>
            ),
        },
        {
            Header: 'Processor',
            accessor: 'processorName',
            Cell: ({ value, row }: { value: string; row: any }) => (
                <div className="flex-column flex-gap-5">
                    <div className="flex-row flex-gap-5 flex-align-start-center">
                        <CardIcon />
                        <span>{value}</span>
                    </div>
                    <div className="secondary-text accent-gradient-text">{row.original.processorTransactionId}</div>
                </div>
            ),
        },
        {
            Header: 'Value',
            accessor: 'amount',
            Cell: ({ value }: { value: string }) => (
                <span className="value-text accent-gradient-text">${formatAmount(+value)}</span>
            ),
        },
        {
            Header: () => (
                <div className='flex-row flex-align-center-center' style={{ textAlign: 'center' }} >
                    Status
                </div>
            ),
            accessor: 'status',
            Cell: ({ value, row }: { value: string; row: any }) => (
                <div
                    className={`status ${value?.toLowerCase()}
                 flex-row flex-align-center-center`}
                    onClick={() => navigate(`${baseAppPath}/transactions/transaction/${row.original.id}`)}
                >
                    <span>{value}</span>
                </div>
            ),
        },
        {
            Header: () => (
                <div className='flex-row flex-align-center-center'>
                    Time
                </div>
            ),
            accessor: 'date',
            Cell: ({ value }: { value: string }) => (
                <div className="date-text">{dateService.formatDateForTable(value)}</div>
            ),
        },

    ];

    const handleMerchantNavigate = (id: string) => {
        navigate(`${baseAppPath}merchants/merchant/${id}`);
    }


    return (
        <div className='dashboard-page page-content'>
            <div className='flex-row flex-align-space-between-start width-100'>
                <div className='page-title'>
                    Dashboard
                </div>
                <DashboardPagePartSummary transactionsNo={transactionsSummary?.transactionCount || 0} amount={transactionsSummary?.totalAmount || 0} commission={9000} />
            </div>
            <StatisticSection title='Status Statistics'>
                <StatisticsSectionChartType data={transactionsStatus || []} />
            </StatisticSection>
            {cardTypes.length > 0 &&
                <StatisticSection title='Card Brand Statistics'>
                    <StatisticsSectionChartType data={cardTypes || []} />
                </StatisticSection>
            }

            <StatisticSection title='Processors Statistics'>
                <StatisticsSectionBarChartType data={processors || []} />
            </StatisticSection>
            {  !isMerchant && 
            <StatisticSection title='Merchants Statistics'>
                <StatisticsSectionBarChartType data={merchants || []} onNavigate={handleMerchantNavigate} />
            </StatisticSection>
            }
            <div className='inner-page flex-column flex-gap-20 width-100'>
                <StatisticSection title='Recent Transactions'>
                    <TableContent columns={columnsRecentTransactions} data={recentTransactions || []}></TableContent>
                </StatisticSection>
            </div>
        </div>
    )
}
export default DashboardPage; 