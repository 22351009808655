import { Outlet } from "react-router-dom";
import SideMenu from "../../components/side-menu";
import './app-layout.scss';


const AppLayout = () => {
    return (
        <div className="app-layout flex-row">
                <div className="side-menu-placeholder"></div>
                <SideMenu  />
                <Outlet /> 
        </div>)
}

export default AppLayout;