import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import AppFormInput from '../../../../components/form/app-form-input';
import ProcessorField from './add-merchant.part.form.add-processor';
import AddElementButton from '../../../../components/form/add-element-button';
import Button from '../../../../components/button';
import './add-merchant.part.form.scss';
import { apiService } from '../../../../services/api.service';
import { useNavigate } from 'react-router-dom';
import { ProcessorSecurityType } from '../../../../types/processor.types';
import { errorService } from '../../../../services/error.service';


export interface AddMerchantForm {
    name: string;
    merchantEmail: string;
    merchantPassword: string;
    // iban: string;
    midList: MID[];
    securityType: ProcessorSecurityType;
    secret: string;

}

interface MID {
    processorId?: string,
    midsDetails: MIDDetails[];
}

export interface MIDDetails {
    midName: string,
    trashHoldLimit?: number
    md5: string;
    privateKey: string;
    midCode: string;
    commissions?: CommissionFormField[]
}

export enum CommissionValueType {
    PERCENT = 'PERCENT',
    FIXED = 'FIXED'
}

export enum CommissionType {
    MDR = 'MDR',
    ROLLING = 'ROLLING',
    TRANSACTION = 'TRANSACTION',
    REFUND = 'REFUND',
    CHARGEBACK = 'CHARGEBACK',
    SETTLEMENT = 'SETTLEMENT'
}

interface CommissionFormField {
    value?: number;
    valueType: CommissionValueType;
    type: CommissionType;
}



const AddMerchantPartForm: React.FC = () => {
    const navigate = useNavigate();
    const form = useForm<AddMerchantForm>({
        defaultValues: {
            midList: [
                {
                    processorId: '',
                    midsDetails: [
                        {
                            midName: '',
                            md5: '',
                            midCode: '',
                            privateKey: '',
                            commissions: Object.values(CommissionType).map((type) => ({
                                valueType: CommissionValueType.PERCENT,
                                type,
                                anyValue: false,
                            })),
                        },
                    ],
                },
            ],
        },
    });

    const { fields: processorFields, append: addProcessor, remove: removeProcessor } = useFieldArray({
        control: form.control,
        name: 'midList',
    });


    const onCreateMerchant = async (data: AddMerchantForm) => {
        const transformedData = {
            merchant: {
                lastName: data.name,
                userName: data.name,
                email: data.merchantEmail,
                password: data.merchantPassword,
            },
            processorsList: data.midList.map((processor) => ({
                processorId: processor.processorId,
                midList: processor.midsDetails.map((mid) => ({
                    mid: {
                        midName: mid.midName,
                        trashHoldLimit: Number(mid.trashHoldLimit),
                        md5: mid.md5,
                        midCode: mid.midCode,
                        privateKey: mid.privateKey
                    },
                    commissions: mid.commissions?.map((commission) => ({
                        commissionType: commission.type,
                        commissionAmount: Number(commission.value),
                        isPercent: commission.valueType === "PERCENT",
                        isFixed: commission.valueType === "FIXED",
                    })) || [],
                })),
            })),
        };

        try {
            await apiService.createMerchant(transformedData);
            errorService.showSuccess('Success', `Merchant ${data.name} has been added!`)
            navigate(-1);
        } catch (error: any) {
            errorService.showApiError(error)
        }
    }


    return (
        <div className="add-merchant-form flex-column flex-gap-30">
            <div className="flex-column flex-gap-15">
                <span className="label">General information</span>
                <div className="flex-row flex-gap-20">
                    <AppFormInput
                        control={form.control}
                        name="name"
                        placeholder="Name"
                        validations={{ required: true }}
                    />
                    <AppFormInput
                        control={form.control}
                        type="email"
                        name="merchantEmail"
                        placeholder="Email Merchant ADMIN"
                        validations={{ required: true }}
                    />
                    <AppFormInput
                        control={form.control}
                        name="merchantPassword"
                        type="password"
                        placeholder="Password Merchant ADMIN"
                        validations={{ required: true }}
                    />
                </div>
            </div>

            <div className="flex-column flex-gap-15">
                <span className="label">Processors</span>

                {processorFields.map((processor, index) => (
                    <ProcessorField
                        key={processor.id}
                        form={form}
                        processorIndex={index}
                        removeProcessor={removeProcessor}
                    />
                ))}

                <div className='flex-row'>
                    <div className='flex-1-form'>
                        <AddElementButton
                            text="Add processor"
                            onClick={() =>
                                addProcessor([{
                                    midsDetails: [{
                                        midName: '', 
                                        md5: '',
                                        midCode: '',
                                        privateKey: '',
                                        commissions: Object.values(CommissionType).map((type) => ({
                                            valueType: CommissionValueType.PERCENT,
                                            type,
                                            anyValue: false,
                                        })),
                                    }]
                                }])
                            }
                        />
                    </div>
                    <div className='flex-2-form'></div>
                </div>
            </div>


            <div className='flex-row flex-align-end-end flex-gap-40'>
                <Button
                    label='Cancel'
                    theme='secondary'
                    onClick={() => navigate(-1)}
                />
                <Button
                    label='Save'
                    disabled={!form.formState.isValid}
                    onClick={form.handleSubmit(onCreateMerchant)}
                />
            </div>
        </div>
    );
};

export default AddMerchantPartForm