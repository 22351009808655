import { getColorByStatusType, Statistic, StatusType } from "../types/statistics.types";

export const mockMerchantsTable = [
    {
        merchantId: '1234',
        merchantName: 'RGS Merch',
        transactions: [
            {
                status: StatusType.APPROVED,
                percent: 25,
            },
            {
                status: StatusType.CHARGEDBACK,
                percent: 25,
            },
            {
                status: StatusType.DECLINED,
                percent: 25,
            },
            {
                status: StatusType.REFUNDED,
                percent: 12.5,
            },
            {
                status: StatusType.ERROR,
                percent: 12.5,
            },
        ],
    },
    {
        merchantId: '1235',
        merchantName: 'Merchant A',
        transactions: [
            {
                status: StatusType.APPROVED,
                percent: 25,
            },
            {
                status: StatusType.CHARGEDBACK,
                percent: 12.5,
            },
            {
                status: StatusType.DECLINED,
                percent: 25,
            },
            {
                status: StatusType.REFUNDED,
                percent: 25,
            },
            {
                status: StatusType.ERROR,
                percent: 37.5,
            },
        ],
    },
    {
        merchantId: '1236',
        merchantName: 'Merchant B',
        transactions: [
            {
                status: StatusType.APPROVED,
                percent: 25,
            },
            {
                status: StatusType.CHARGEDBACK,
                percent: 0,
            },
            {
                status: StatusType.DECLINED,
                percent: 37.5,
            },
            {
                status: StatusType.REFUNDED,
                percent: 25,
            },
            {
                status: StatusType.ERROR,
                percent: 12.5,
            },
        ],
    },
    {
        merchantId: '1237',
        merchantName: 'Merchant C',
        transactions: [
            {
                status: StatusType.APPROVED,
                percent: 50,
            },
            {
                status: StatusType.CHARGEDBACK,
                percent: 0,
            },
            {
                status: StatusType.DECLINED,
                percent: 25,
            },
            {
                status: StatusType.REFUNDED,
                percent: 12.5,
            },
            {
                status: StatusType.ERROR,
                percent: 12.5,
            },
        ],
    },
    {
        merchantId: '1238',
        merchantName: 'Merchant D',
        transactions: [
            {
                status: StatusType.APPROVED,
                percent: 25,
            },
            {
                status: StatusType.CHARGEDBACK,
                percent: 25,
            },
            {
                status: StatusType.DECLINED,
                percent: 25,
            },
            {
                status: StatusType.REFUNDED,
                percent: 12.5,
            },
            {
                status: StatusType.ERROR,
                percent: 37.5,
            },
        ],
    },
];

export const mockMidsTable = [
    {
        midId: '1123',
        midCode: 'New Merchant MID 1',
        trashHoldLimit: '90000',
        merchantName: 'RGS Merch',
        processorName: 'Stripe',
        usage: 50,
    },
    {
        midId: '1124',
        midCode: 'MID 2',
        trashHoldLimit: '50000',
        merchantName: 'RGS Merch',
        processorName: 'AzulPay',
        usage: 25,
    },
    {
        midId: '1125',
        midCode: 'MID 1',
        trashHoldLimit: '100000',
        merchantName: 'Merchant A',
        processorName: 'Stripe',
        usage: 75,
    },
    {
        midId: '1126',
        midCode: 'MID 3',
        trashHoldLimit: '150000',
        merchantName: 'RGS Merch',
        processorName: 'Netopia',
        usage: 50,
    },
    {
        midId: '1127',
        midCode: 'MID 1',
        trashHoldLimit: '40000',
        merchantName: 'Merchant C',
        processorName: 'Stripe',
        usage: 100,
    },
];

export const mockMidTransactions = [
    {
        id: '1111aaaa00000001',
        customerName: 'John Doe',
        cartType: 'Visa Card',
        cardLast4: '1111',
        merchantName: 'RGS Merch',
        merchantId: 'RGSMerchantID1111',
        processorName: 'Stripe',
        transactionId: 'STRIPEid1111',
        amount: 200,
        status: StatusType.APPROVED,
        date: '10:30 01.11.2024',
    },
    {
        id: '2222aaaa00000002',
        customerName: 'Jane Smith',
        cartType: 'MasterCard',
        cardLast4: '2222',
        merchantName: 'Merchant A',
        merchantId: 'MerchantAID2222',
        processorName: 'Netopia',
        transactionId: 'NETOPIAid2222',
        amount: 300,
        status: StatusType.CHARGEDBACK,
        date: '11:45 01.11.2024',
    },
    {
        id: '3333aaaa00000003',
        customerName: 'Emily Davis',
        cartType: 'American Express',
        cardLast4: '3333',
        merchantName: 'Merchant B',
        merchantId: 'MerchantBID3333',
        processorName: 'AzulPay',
        transactionId: 'AZULPAYid3333',
        amount: 500,
        status: StatusType.REFUNDED,
        date: '13:15 01.11.2024',
    },
    {
        id: '4444aaaa00000004',
        customerName: 'Michael Brown',
        cartType: 'Discover',
        cardLast4: '4444',
        merchantName: 'Merchant C',
        merchantId: 'MerchantCID4444',
        processorName: 'Stripe',
        transactionId: 'STRIPEid4444',
        amount: 100,
        status: StatusType.DECLINED,
        date: '14:00 01.11.2024',
    },
    {
        id: '5555aaaa00000005',
        customerName: 'Chris Wilson',
        cartType: 'Visa Card',
        cardLast4: '5555',
        merchantName: 'RGS Merch',
        merchantId: 'RGSMerchantID5555',
        processorName: 'Netopia',
        transactionId: 'NETOPIAid5555',
        amount: 50,
        status: StatusType.ERROR,
        date: '16:45 01.11.2024',
    },
];


export const mockTransactions = [
    {
        id: '1111aaaa00000001',
        customerName: 'John Doe',
        cartType: 'Visa Card',
        cardLast4: '1111',
        merchantName: 'RGS Merch',
        merchantId: 'RGSMerchantID1111',
        processorName: 'Stripe',
        transactionId: 'STRIPEid1111',
        amount: 200,
        status: StatusType.APPROVED,
        date: '10:30 01.11.2024',
        allowedActions: ['Refund', 'CHARGEDBACK']
    },
    {
        id: '2222aaaa00000002',
        customerName: 'Jane Smith',
        cartType: 'MasterCard',
        cardLast4: '2222',
        merchantName: 'Merchant A',
        merchantId: 'MerchantAID2222',
        processorName: 'Netopia',
        transactionId: 'NETOPIAid2222',
        amount: 300,
        status: StatusType.CHARGEDBACK,
        date: '11:45 01.11.2024',
        allowedActions: ['Full refund']
    },
    {
        id: '3333aaaa00000003',
        customerName: 'Emily Davis',
        cartType: 'American Express',
        cardLast4: '3333',
        merchantName: 'Merchant B',
        merchantId: 'MerchantBID3333',
        processorName: 'AzulPay',
        transactionId: 'AZULPAYid3333',
        amount: 500,
        status: StatusType.REFUNDED,
        date: '13:15 01.11.2024',
    },
    {
        id: '4444aaaa00000004',
        customerName: 'Michael Brown',
        cartType: 'Discover',
        cardLast4: '4444',
        merchantName: 'Merchant C',
        merchantId: 'MerchantCID4444',
        processorName: 'Stripe',
        transactionId: 'STRIPEid4444',
        amount: 100,
        status: StatusType.DECLINED,
        date: '14:00 01.11.2024',
        allowedActions: ['Full refund']
    },
    {
        id: '5555aaaa00000005',
        customerName: 'Chris Wilson',
        cartType: 'Visa Card',
        cardLast4: '5555',
        merchantName: 'RGS Merch',
        merchantId: 'RGSMerchantID5555',
        processorName: 'Netopia',
        transactionId: 'NETOPIAid5555',
        amount: 50,
        status: StatusType.ERROR,
        date: '16:45 01.11.2024',
    },
];

export const getBarChartSegments = (
    transactions: { status: StatusType; percent: number }[],
) => {
    return transactions.map((transaction) => {
        return {
            percent: transaction.percent,
            color: getColorByStatusType(transaction?.status), 
            label: `${transaction.percent}%`,
        };
    });
};