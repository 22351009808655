import { UserModel } from "../models";
import { UserState } from "../store/user.slice";

export const getBaseAppPath = (user: UserModel | null): string => {
    if (!user) {
        return '/auth'; 
    }

    if (user.roles?.includes('merchant')) {
        return '/xcharger/app';
    }

    if (user.roles?.includes('pg_admin')) {
        return '/paybridge/app';
    }

    return '/auth'; 
};