import { useNavigate } from "react-router-dom";
import Button from "../../../components/button";
import StatisticSection from "../../../components/statistics-section";
import StatisticsSectionBarChartType from "../../../components/statistics-section.bar-chart-type";
import BarChart from "../../../components/bar-chart";
import { getBarChartSegments, mockMerchantsTable } from "../../../mock/table";
import { StatusType, getColorByStatusType, getRandomColor } from "../../../types/statistics.types";
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import TableContent from "../../../components/table-content";
import { useEffect, useState } from "react";
import { apiService } from "../../../services/api.service";
import { useSelector } from "react-redux";
import { AppStore } from "../../../store/store";
import { UserState } from "../../../store/user.slice";
import { colorsPalette } from "../../../utils/colors-utils";
import AppModalMerchant from "../../../components/app-modal-merchant";

const MerchantsPage = () => {

    const navigate = useNavigate();

    const [isModalVisible, setModalVisible] = useState(false);

    const [modalData, setModalData] = useState({
        id: '',
        merchantName: '',
    });

    const [merchantsTableData, setMerchantsTableData] = useState();

    const [merchantsStatistic, setMerchantsStatistic] = useState();

    const { user } = useSelector<AppStore, UserState>((state) => state.user)

    const columnsMerchants = [
        {
            Header: 'Name',
            accessor: 'merchantName',
            Cell: ({ value }: { value: string }) => {
                return (
                    <span>{value}</span>
                )
            },
        },
        {
            Header: () => (
                <div className='flex-row flex-align-space-between-center' >
                    <span>Transactions - Status Distribution</span>
                    <div className='flex-row flex-gap-10 flex-wrap' >
                        {Object.values(StatusType).map((status) => (
                            <div className='flex-row flex-align-center-center flex-gap-5' key={status}>
                                <div className='legend-status-color'
                                    style={{
                                        backgroundColor: getColorByStatusType(status),
                                    }}
                                ></div>
                                <span className='legend-status-text' style={{ color: getColorByStatusType(status) }}>{status}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ),
            accessor: 'transactions',
            Cell: ({ value }: { value: any }) => {
                const transformedData = getBarChartSegments(value);
                return (
                    <>
                        <BarChart segments={transformedData}></BarChart>
                    </>
                )
            }
        },
        {
            Header: 'Actions',
            accessor: '',
            Cell: ({ value, row }: { value: any, row: any }) => (
                <div className='flex-row flex-align-space-between-center'>
                    <EditIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate(`edit-merchant/${row.original.merchantId
                                }`)
                        }}
                    />
                    { row.original.isEnabled &&
                        <DeleteIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => {

                                setModalData({
                                    id: row.original.merchantId,
                                    merchantName: row.original.merchantName,
                                });
                                setModalVisible(true);
                            }}
                        />
                    }
                </div>
            ),
        },

    ];

    useEffect(() => {
        apiService.getMerchantsTransactionsPercents()
            .then((response) => {
                const transformedTableData = response.data.map((item: any, index: number) => ({
                    ...item,
                    color: colorsPalette[index],
                }));
                setMerchantsTableData(transformedTableData);
            })
            .catch((error) => console.error('Error fetching merchants transactions percents:', error));

        apiService.getMerchantStatistics()
            .then((response) => {
                const transformedStatistics = response.data.map((item: any, index: number) => ({
                    id: item.id,
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                    color: colorsPalette[index],
                }));
                setMerchantsStatistic(transformedStatistics);
            })
            .catch((error) => console.error('Error fetching merchants statistics:', error));
    }, [user]);

    const handleMerchantNavigate = (id: string) => {
        navigate(`merchant/${id}`);
    }

    const handleDisableMerchant = async (id: string) => {
        try {
            await apiService.disableMerchant(id)
            apiService.getMerchantsTransactionsPercents()
                .then((response) => {
                    const transformedTableData = response.data.map((item: any, index: number) => ({
                        ...item,
                        color: colorsPalette[index],
                    }));
                    setMerchantsTableData(transformedTableData);
                })
                .catch((error) => console.error('Error fetching merchants transactions percents:', error));

            apiService.getMerchantStatistics()
                .then((response) => {
                    const transformedStatistics = response.data.map((item: any, index: number) => ({
                        id: item.id,
                        name: item.name,
                        quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                        quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                        qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                        qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                        color: colorsPalette[index],
                    }));
                    setMerchantsStatistic(transformedStatistics);
                })
                .catch((error) => console.error('Error fetching merchants statistics:', error));
            setModalVisible(false);
        } catch (e) {
            console.log(e);
        }
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    return (
        <div className="merchants-page page-content">
            <div className="page-header flex-row flex-gap-20 flex-align-start-center">
                <div className='page-title'>
                    Merchants
                </div>
                <Button
                    label="Add Merchant"
                    onClick={() => navigate('add-merchant')}
                />
            </div>
            <div className='width-100'>
                <TableContent columns={columnsMerchants} data={merchantsTableData || []}></TableContent>
            </div>
            <StatisticSection title='Merchants Statistics'>
                <StatisticsSectionBarChartType data={merchantsStatistic || []} onNavigate={handleMerchantNavigate} />
            </StatisticSection>

            <AppModalMerchant
                text={`Are you sure you want to DELETE the Merchant `}
                id={modalData.id}
                merchantName={modalData.merchantName}
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                confirmAction={handleDisableMerchant}
                cancelAction={handleCancel}
                isVisible={isModalVisible}
                status={StatusType.ERROR as StatusType}
            />
        </div>
    )
}

export default MerchantsPage;