import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import './auth-page.layout.scss';

const payBridgeLogo = require('../../assets/images/pay-bridge-logo-black.png');


const AuthLayout = () => {
    const { t } = useTranslation();
    return (
        <div className="auth-page-layout-container flex-column flex-align-center-center flex-gap-50" >
            <div className="header-container flex-column flex-align-center-center">
                <img src={payBridgeLogo} width={350} alt="PayBridge Logo" />
                {/* <div className="title-container flex-column flex-align-center-center">
                    <div>
                    <span className="title-text">PayBridge</span>
                    </div>
                </div> */}
            </div>
            <Outlet></Outlet>
        </div>
    );

}

export default AuthLayout;