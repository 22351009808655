import ProgressBarChart from "../../../../components/progress-bart-chart";
import TableContent from "../../../../components/table-content";
import { ReactComponent as ArrowBackIcon } from '../../../../assets/icons/arrow-back.svg';
import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';
import { ReactComponent as MerchantIcon } from '../../../../assets/icons/merchant.svg';
import { ReactComponent as CardIcon } from '../../../../assets/icons/processor-card.svg';
import { mockMidTransactions } from "../../../../mock/table";
import { useNavigate, useParams } from "react-router-dom";
import './mid-transactions-page.scss';
import { useEffect, useState } from "react";
import { apiService } from "../../../../services/api.service";
import { useSelector } from "react-redux";
import { AppStore } from "../../../../store/store";
import { UserState } from "../../../../store/user.slice";
import dateService from "../../../../services/date.service";
import TablePagination from "../../../../components/table-pagination";
import { getBaseAppPath } from "../../../../utils/path-utils";


interface MidDetails {
    id: string;
    name: string;
    limit: number;
    merchantName: string;
    processorName: string;
    percentUsage: number;
}



const MidTransactionsPage: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [ transactions, setTransactions ] = useState();
    const [midDetails, setMidDetails] = useState<MidDetails>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1)
    const { user } = useSelector<AppStore, UserState>((state) => state.user)

    const baseAppPath = user ? getBaseAppPath(user) : '';

    const columnsMidTransactions = [
        {
            Header: 'Trace ID',
            accessor: 'id',
            Cell: ({ value }: { value: string }) => (
                <span 
                className="id-text truncated-text"
                onClick={() => navigate(`${baseAppPath}/transactions/transaction/${value}`)}
                >{value}</span>
            ),
        },
        {
            Header: 'FROM Customer',
            accessor: 'customerName',
            Cell: ({ value, row }: { value: string; row: any }) => (
                <div className="flex-column flex-gap-5">
                    <div className="flex-row flex-gap-5 flex-align-start-center">
                        <UserIcon />
                        <span>{value}</span>
                    </div>
                    <div className="secondary-text accent-gradient-text">
                        {row.original.cartType} - xxxx xxxx xxxx {row.original.cardLast4}
                    </div>
                </div>
            ),
        },
        {
            Header: 'TO Merchant',
            accessor: 'merchantName',
            Cell: ({ value, row }: { value: string; row: any }) => (
                <div className="flex-column flex-gap-5">
                    <div className="flex-row flex-gap-5 flex-align-start-center">
                        <MerchantIcon />
                        <span>{value}</span>
                    </div>
                    <div className="secondary-text accent-gradient-text">{row.original.merchantId}</div>
                </div>
            ),
        },
        {
            Header: 'Processor',
            accessor: 'processorName',
            Cell: ({ value, row }: { value: string; row: any }) => (
                <div className="flex-column flex-gap-5">
                     <div className="flex-row flex-gap-5 flex-align-start-center">
                        <CardIcon />
                        <span>{value}</span>
                    </div>
                    <div className="secondary-text accent-gradient-text">{row.original.transactionId}</div>
                </div>
            ),
        },
        {
            Header: 'Value',
            accessor: 'amount',
            Cell: ({ value }: { value: string }) => (
                <span className="value-text accent-gradient-text">${value}</span>
            ),
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value, row }: { value: string; row: any }) => (
                <div 
                className={`status ${value.toLowerCase()}
                 flex-row flex-align-center-center`}
                 onClick={() =>  navigate(`${baseAppPath}/transactions/transaction/${row.original.id}`)}
                 >
                    <span>{value}</span>
                </div>
            ),
        },
        {
            Header: 'Time',
            accessor: 'date',
            Cell: ({ value }: { value: string }) => (
                <div className="date-text">{dateService.formatDateForTable(value)}</div>
            ),
        },
    ];


    useEffect(() => {
        const getMidLimit = async () => {
            try {
                const response = await apiService.getMidLimitById(id!)
                return response.data;
            }  catch (e) {

            } 
        } 
        const getMidTransactions = async () => {
            try { 
            const response = await apiService.getTransactions({midId: id});
            return response.data
            } catch (e) {

            } 
        }
        if(id)  {

        getMidLimit().then((result) => {
            if(result)
            setMidDetails(result)
        })

        getMidTransactions().then((result) => {
            if(result)
            setTransactions(result.data)
        })
        }
    }, [id, user])

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    
    return (
        <div className="mid-transactions-page page-content">
            <div className="page-header flex-row flex-gap-10 flex-align-start-center">
                <ArrowBackIcon
                    className="back-icon"
                    onClick={() => navigate(-1)}
                />
                <div className='page-title'>
                    View Transactions - <span className="page-subtitle">{midDetails?.name}</span>
                </div>

            </div>
            
            <div className='inner-page flex-column flex-gap-30 width-100'>
                <div className="flex-row flex-gap-20 flex-align-center-center">
                    <ProgressBarChart percent={midDetails?.percentUsage || 0} />
                    <span className="mid-total-value">{midDetails?.limit}$</span>
                </div>
                <TableContent columns={columnsMidTransactions} data={transactions || []}></TableContent>
                <TablePagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>

        </div>
    );
}

export default MidTransactionsPage;