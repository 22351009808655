import { useNavigate } from 'react-router-dom';
import TimeFilters from '../../../../components/time-filters';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { apiService } from '../../../../services/api.service';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';
import { getColorByCardBrandType, StatusType } from '../../../../types/statistics.types';
import StatisticSection from '../../../../components/statistics-section';
import StatisticsSectionBarChartType from '../../../../components/statistics-section.bar-chart-type';
import StatisticsSectionChartType from '../../../../components/statistics-section.chart-type';
import './reports-status-page.scss';
import { colorsPalette } from '../../../../utils/colors-utils';
import AppSelect from '../../../../components/form/base/app-select';
import { getBaseAppPath } from '../../../../utils/path-utils';


const ReportsStatusPage: React.FC = () => {
    const navigate = useNavigate();
    const [ startDate, setStartDate ] = useState<string>();
    const [ endDate, setEndDate ] = useState<string>();
    const [transactionsNumber, setTransactionsNumber ] = useState();
    const [cardTypes, setCardTypes] = useState();
    const [processors, setProcessors] = useState();
    const [merchants, setMerchants] = useState();
    const [selectedStatus, setSelectedStatus] = useState<StatusType>();
    const { user } = useSelector<AppStore, UserState>((state) => state.user)
    const statusOptions = Object.values(StatusType).map((status) => ({
        label: status,
        value: status,
    }));

    const isMerchant = user?.roles?.includes("merchant") ?? false;

    const baseAppPath = user ? getBaseAppPath(user) : '';

    useEffect(() => {
        apiService.getTransactionsSummary({startDate, endDate, status: selectedStatus})
        .then((response) => {setTransactionsNumber(response.data.transactionCount)})
        .catch((error) => console.error('Error fetching transactions number:', error));


        apiService.getCardTypeStatistics({startDate, endDate, status: selectedStatus})
            .then((response) => {
                const transformedData = response.data.map((item: any) => ({
                    color: getColorByCardBrandType(item.name),
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setCardTypes(transformedData);
            })
            .catch((error) => console.error('Error fetching card types:', error));

        apiService.getProcessorStatistics({startDate, endDate, status: selectedStatus})
            .then((response) => {
                const transformedData = response.data.map((item: any, index: number) => ({
                    color: colorsPalette[index],
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setProcessors(transformedData);
            })
            .catch((error) => console.error('Error fetching processors:', error));
        if(!isMerchant && user) {
            apiService.getMerchantStatistics({startDate, endDate, status: selectedStatus})
            .then((response) => {
                const transformedData = response.data.map((item: any, index: number) => ({
                    id: item.id,
                    color: colorsPalette[index],
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setMerchants(transformedData);
            })
            .catch((error) => console.error('Error fetching merchants:', error));
        }

    }, [user?.id, startDate, endDate, selectedStatus]);

    const handleMerchantNavigate = (id: string) => {
        navigate(`${baseAppPath}/merchants/merchant/${id}`);
    }


    const handleDataUpdate = (startDate?: string, endDate?: string) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    return (
        <div className='reports-status-page page-content'>
              <div className="page-header flex-row flex-gap-20 flex-align-start-center width-100">
                <div className='page-title'>
                    Reports Status          
                </div>
                <AppSelect
                    theme='filter'
                    value={selectedStatus}
                    options={statusOptions}
                    placeholder='Select Status'
                    onChange={(value) => setSelectedStatus(value)}
                />
            </div> 
            <div>
                <TimeFilters transactionsNo={transactionsNumber} handleFilters={handleDataUpdate}/>
            </div>
         
            <StatisticSection title='Card Brand Statistics'>
                <StatisticsSectionChartType data={cardTypes || []} />
            </StatisticSection>
            <StatisticSection title='Processors Statistics'>
                <StatisticsSectionBarChartType data={processors || []} />
            </StatisticSection>
            {!isMerchant &&
            <StatisticSection title='Merchants Statistics'>
                <StatisticsSectionBarChartType data={merchants || []} onNavigate={handleMerchantNavigate} />
            </StatisticSection>
            }
        </div>
    )
}

export default ReportsStatusPage;