import { LabelValue } from "./label-value.types";
import { IntermediateStatusType, StatusType } from "./statistics.types";

export interface TransactionLog {
    id: string;
    transactionId: string;
    initiator: string;
    date: string;
    newStatus: StatusType;
    newIntermediateStatus: string;
    processorInternalStatus: string | null;
}

export interface TransactionSummary {
    transactionCount: number;
    totalAmount: number
}

export interface TransactionDetails {
    id: string;
    customerName: string;
    customerCardType: string;
    customerLastFourCardDigits: string;
    customerCardNumber: string | null; 
    merchantId: string;
    merchantName: string;
    midId: string;
    processorId: string;
    processorName: string;
    processorTransactionId: string;
    amount: number;
    fee: number | null;
    status: StatusType;
    method: string; 
    intermediateStatus: string;
    date: string;
    linkedTransaction: string | null;
    internalErrorMessage: string | null; 
    allowedActions: string[] | null;
    logs: TransactionLog[]; 
    processorStatus: string | null;
    processorErrorMessage: string | null; 
}


export interface BillingDetails {
    id: string;
    transactionId: string;
    firstName: string;
    lastName: string;
    country: string;
    city: string;
    administrativeArea: string;
    addressLine1: string;
    addressLine2: string | null;
    postalCode: string;
    email: string;
    phoneNumber: string;
}



 export const approvedActions = [
    {
        label: StatusType.CHARGEDBACK,
        value: StatusType.CHARGEDBACK
    },
    {
        label: StatusType.REFUNDED,
        value: StatusType.REFUNDED
    }
]

export const pendingApproval = [

    {
        label: StatusType.DECLINED,
        value: StatusType.DECLINED
    }
]

export const pendingChargeback = [

    {
        label: StatusType.CHARGEDBACK,
        value: StatusType.CHARGEDBACK
    }
]


export const pendingRefund = [
    {
        label: StatusType.REFUNDED,
        value: StatusType.REFUNDED
    }
]


export const generateOptions = (
    status: StatusType,
    intermediateStatus: IntermediateStatusType
): LabelValue<StatusType>[] => {
    const availableNextStatusList: StatusType[] = [];

    if (status === StatusType.PENDING && intermediateStatus === IntermediateStatusType.PENDING_APPROVE) {
        availableNextStatusList.push(StatusType.DECLINED);
    }

    if (status === StatusType.PENDING && intermediateStatus === IntermediateStatusType.PENDING_CHARGEBACK) {
        availableNextStatusList.push(StatusType.CHARGEDBACK);
    }

    if (status === StatusType.PENDING && intermediateStatus === IntermediateStatusType.PENDING_REFUND) {
        availableNextStatusList.push(StatusType.REFUNDED);
    }

    if (status === StatusType.APPROVED && intermediateStatus === IntermediateStatusType.DONE) {
        availableNextStatusList.push(StatusType.CHARGEDBACK);
    }


    return availableNextStatusList.map((action) => ({
        label: action,
        value: action,
    }));
};