import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowBackIcon } from '../../../../assets/icons/arrow-back.svg';
import StatisticSection from '../../../../components/statistics-section';
import { useEffect, useState } from 'react';
import { apiService } from '../../../../services/api.service';
import './transaction-details-page.scss';
import TransactionDetailsPartPaymentInformation from './transaction-details.part.payment-information';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';
import TransactionDetailsPartBillingInformation from './transaction-details.part.billing-information';
import TransactionDetailsPartTransactionInformation from './transaction-details.part.transaction-information';
import { BillingDetails, TransactionDetails } from '../../../../types/transaction.types';
import dateService from '../../../../services/date.service';



interface TransactionDetailsPageProps {

}



const TransactionDetailsPage: React.FC<TransactionDetailsPageProps> = () => {
    const navigate = useNavigate();
    const [transactionDetails, setTransactionDetails] = useState<TransactionDetails[]>();
    const [billingDetails, setBillingDetails] = useState<BillingDetails>();
    const [orderInformation, setOrderInformation] = useState<any>(null);
    const [paymentInformation, setPaymentInformation] = useState<any>(null);
    const [ infoMessage, setInfoMessage  ] = useState();
    const { user } = useSelector<AppStore, UserState>((state) => state.user)
    const { id } = useParams();
    
    useEffect(() => {
        if (!id) return;
        apiService
            .getTransactionById(id)
            .then((response) => {
                const data = response.data;
                setTransactionDetails(data);
                
                const orderInfo = {
                    merchantId: data[0].merchantId || 'N/A',
                    merchantName: data[0].merchantName || 'N/A',
                    accountId: data[0].midId || 'N/A',
                    accountName: data[0].processorName || 'N/A',
                    amount: data[0].amount || 0,
                    orderId: data[0].processorTransactionId || 'N/A',
                    merchantOrderId: data[0].linkedTransaction || 'N/A',
                    requestTime: data[0].date ? dateService.formatDateForTable( data[0].date) : 'N/A',
                };
                setOrderInformation(orderInfo);

                const paymentInfo = {
                    totalAuthorizedAmount: data[0].amount || 0,
                    totalCapturedAmount: data[0].amount || 0,
                    name: data[0].customerName || 'N/A',
                    cardNo: data[0].customerLastFourCardDigits
                        ? `xxxx xxxx xxxx ${data[0].customerLastFourCardDigits}`
                        : 'N/A',
                    cardType: data[0].customerCardType || 'N/A',
                    expiryDate: '**/****',
                };
                setPaymentInformation(paymentInfo);
            })
            .catch((error) => {
                console.error('Failed to fetch transaction details:', error);
            });

        apiService
            .getBillingDetails(id)
            .then((response) => {
                setBillingDetails(response.data);
            })
            .catch((error) => {
                console.error('Failed to fetch billing details:', error);
            });


        apiService.getTransactionStatus(id)
            .then((response) => {
                setInfoMessage(response.data)
            })
            .catch((error) => {
                console.error('Failed to fetch billing details:', error);
            });

    }, [user?.id, id])

    return (
        <div className="transaction-details-page page-content">
            <div className="page-header flex-row flex-gap-10 flex-align-start-center width-100">
                <ArrowBackIcon
                    className="back-icon"
                    onClick={() => navigate(-1)}
                />
                <div className='page-title width-100 flex-align-space-between-center'>
                    Transaction - <span className="page-subtitle">{transactionDetails?.[0].id}</span>
                </div>
                <div className={`status ${transactionDetails?.[transactionDetails?.length - 1]?.status?.toLocaleLowerCase()} flex-row flex-align-center-center`}>
                    {transactionDetails?.[transactionDetails?.length - 1]?.status}
                </div>
            </div>
            <StatisticSection title='Transaction Information'>
                {transactionDetails &&
                    <TransactionDetailsPartTransactionInformation errorMessage={infoMessage} transactionDetails={transactionDetails} />
                }
            </StatisticSection>

            <StatisticSection title='Payment Information'>
                {orderInformation && paymentInformation && (
                    <TransactionDetailsPartPaymentInformation
                        orderInformation={orderInformation}
                        paymentInformation={paymentInformation}
                    />
                )}
            </StatisticSection>

            <StatisticSection title='Billing Information'>
                <TransactionDetailsPartBillingInformation billingDetails={billingDetails} />
            </StatisticSection>

        </div>
    )
}

export default TransactionDetailsPage