import { useEffect, useState } from 'react';
import { LabelValue } from '../types/label-value.types';
import Button from './button';
import AppDatePicker from './form/base/app-datepicker';
import AppSelect from './form/base/app-select';
import './time-filters.scss';
import dayjs, { Dayjs } from 'dayjs';
import dateService from '../services/date.service';
import { useSelector } from 'react-redux';
import { AppStore } from '../store/store';
import { UserState } from '../store/user.slice';
import { attachAxiosApiInterceptors } from '../utils/axios-utils';

const timeOptions: LabelValue[] = [
    {
        label: 'Reset',
        value: '',
    },
    {
        label: 'DAY',
        value: 'day'
    },
    {
        label: 'MONTH',
        value: 'month',
    },
    {
        label: 'YEAR',
        value: 'year'
    },
    {
        label: 'INTERVAL',
        value: 'interval',
    },
];

const months = [
    { label: 'January', value: '1' },
    { label: 'February', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
];

const years = Array.from({ length: new Date().getFullYear() - 2010 + 1 }, (v, k) => ({
    label: `${2010 + k}`,
    value: `${2010 + k}`
}));


interface TimeFiltersProps {
    transactionsNo?: number;
    handleFilters: (startDate?: string, endDate?: string) => void
    resetTrigger?: boolean;
}


const TimeFilters: React.FC<TimeFiltersProps> = ({ transactionsNo, handleFilters, resetTrigger }) => {
    const [time, setTime] = useState<string>();
    const [selectedDayDate, setSelectedDayDate] = useState<Dayjs | null>();
    const [thisButton, setThisButton] = useState(false);
    const [lastButton, setLastButton] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState<string>();
    const [selectedYear, setSelectedYear] = useState<string>();
    const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>();
    const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>();


    useEffect(() => {
        setTime(undefined);
        setSelectedDayDate(null);
        setSelectedMonth(undefined);
        setSelectedYear(undefined);
        setSelectedFromDate(null);
        setSelectedToDate(null);
        handleFilters('', '')
    }, [resetTrigger]);

    const handleDaySelect = (newDate: Dayjs | null): void => {
        setSelectedDayDate(newDate);
        setThisButton(false);
        setLastButton(false);
        handleFilters(dateService.formatDate(newDate), dateService.formatDate(newDate))
    };

    const handleThisButton = ({ startDate, endDate }: { startDate: string; endDate: string }, timeChanged?: boolean) => {
        setSelectedDayDate(null)
        if (timeChanged) {
            setThisButton(true);
            handleFilters(startDate, endDate)
        } else {
            setThisButton(!thisButton);
            if (thisButton) {
                handleFilters('', '')
            } else {
                handleFilters(startDate, endDate)
            }
        }

        setLastButton(false);
    }

    const handleLastButton = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
        setSelectedDayDate(null)
        setLastButton(!lastButton);
        setThisButton(false);
        if (lastButton) {
            handleFilters('', '')
        } else {
            handleFilters(startDate, endDate)
        }
    }

    const handleMonthYear = (year: string, month: string) => {
        const startDate = dayjs(`${year}-${month}-01`).format('YYYY-MM-DD');
        const endDate = dayjs(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD');
        handleFilters(startDate, endDate);
    }

    const handleYear = (year: string) => {
        const startDate = dayjs(`${year}-01-01`).format('YYYY-MM-DD');
        const endDate = dayjs(`${year}-12-31`).format('YYYY-MM-DD');

        handleFilters(startDate, endDate);
    };

    const handleInterval = (fromDate: Dayjs, toDate: Dayjs) => {
        const startDate = fromDate.format('YYYY-MM-DD');
        const endDate = toDate.format('YYYY-MM-DD');

        if (fromDate.isAfter(toDate)) {
            return;
        }

        handleFilters(startDate, endDate);
    };




    const renderTime = () => {
        switch (time) {
            case 'day':
                return (
                    <>
                        <div
                            className={`filter-button flex-row flex-align-center-center ${thisButton ? 'active' : ''}`}
                            onClick={() => handleThisButton(dateService.getToday())}
                        >
                            TODAY
                        </div>
                        <div
                            className={`filter-button flex-row flex-align-center-center ${lastButton ? 'active' : ''}`}
                            onClick={() => handleLastButton(dateService.getYesterday())}
                        >
                            YESTERDAY
                        </div>
                        <AppDatePicker
                            value={selectedDayDate}
                            onChange={handleDaySelect}
                            placeholder='Select DAY'
                            shouldDisableDate={(date) => date.isAfter(dayjs(), 'day')}
                        />
                    </>
                );
            case 'month':
                return (
                    <>
                        <div
                            className={`filter-button flex-row flex-align-center-center ${thisButton ? 'active' : ''}`}
                            onClick={() => handleThisButton(dateService.getThisMonth())}
                        >
                            THIS MONTH
                        </div>
                        <div
                            className={`filter-button flex-row flex-align-center-center ${lastButton ? 'active' : ''}`}
                            onClick={() => handleLastButton(dateService.getLastMonth())}
                        >
                            LAST MONTH
                        </div>
                        <AppSelect
                            theme='filter'
                            value={selectedMonth}
                            options={months}
                            onChange={(value) => {
                                setSelectedMonth(value);
                                setThisButton(false);
                                setLastButton(false);
                                if (value && selectedYear) {
                                    handleMonthYear(selectedYear, value);
                                }
                            }}
                            placeholder='Select MONTH'
                        />
                        <AppSelect
                            theme='filter'
                            value={selectedYear}
                            options={years}
                            onChange={(value) => {
                                setSelectedYear(value);
                                setThisButton(false);
                                setLastButton(false);
                                if (value && selectedMonth) {
                                    handleMonthYear(value, selectedMonth);
                                }
                            }}
                            placeholder='Select YEAR'
                        />
                    </>
                );
            case 'year':
                return (
                    <>
                        <div
                            className={`filter-button flex-row flex-align-center-center ${thisButton ? 'active' : ''}`}
                            onClick={() => handleThisButton(dateService.getThisYear())}
                        >
                            THIS YEAR
                        </div>
                        <div
                            className={`filter-button flex-row flex-align-center-center ${lastButton ? 'active' : ''}`}
                            onClick={() => handleLastButton(dateService.getLastYear())}
                        >
                            LAST YEAR
                        </div>
                        <AppSelect
                            theme='filter'
                            value={selectedYear}
                            options={years}
                            onChange={(value) => {
                                setSelectedYear(value);
                                setThisButton(false);
                                setLastButton(false);

                                if (value) {
                                    handleYear(value);
                                }
                            }}
                            placeholder='Select YEAR'
                        />
                    </>
                );
            case 'interval':
                return (
                    <>
                        <AppDatePicker
                            value={selectedFromDate}
                            onChange={(newDate) => {
                                setSelectedFromDate(newDate);
                                if (newDate && selectedToDate) {
                                    handleInterval(newDate, selectedToDate);
                                }
                            }}
                            placeholder="Select DAY FROM"
                            shouldDisableDate={(date) =>
                                (selectedToDate && date.isAfter(selectedToDate, 'day')) || date.isAfter(dayjs(), 'day')
                            }
                        />
                        <AppDatePicker
                            value={selectedToDate}
                            onChange={(newDate) => {
                                setSelectedToDate(newDate);
                                if (selectedFromDate && newDate) {
                                    handleInterval(selectedFromDate, newDate);
                                }
                            }}
                            placeholder="Select DAY TO"
                            shouldDisableDate={(date) =>
                                (selectedFromDate && date.isBefore(selectedFromDate, 'day')) || date.isAfter(dayjs(), 'day')
                            }
                        />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="time-filters flex-row flex-gap-20">
            <div className="transactions-number flex-row flex-align-center-center">
                {transactionsNo} transactions
            </div>
            <div className='time-selector'>
                <AppSelect
                    theme='filter'
                    value={time}
                    options={timeOptions}
                    onChange={(value) => {
                        if (value == 'day') {
                            handleThisButton(dateService.getToday(), true)
                        }
                        if (value == 'month')
                            handleThisButton(dateService.getThisMonth(), true)
                        if (value == 'year')
                            handleThisButton(dateService.getThisYear(), true)
                        setTime(value);
                        setSelectedMonth('');
                        setSelectedYear('');
                        setSelectedDayDate(null);
                        setSelectedFromDate(null);
                        setSelectedToDate(null);
                    }}
                    placeholder='TIME'
                />
            </div>
            {renderTime()}
        </div>
    );
}

export default TimeFilters;
