import { Toast } from 'primereact/toast';

class ErrorService {
  toast: Toast | null = null;

  constructor() {
    this.toast = null;
  }

  setToastRef(toastRef: Toast | null) {
    this.toast = toastRef;
  }

  showApiError(error: any, title = 'Something went wrong!') {
    let text;

    if (error.response?.data) {
      if (error.response.data?.details) {
        text = error.response.data.details;
      } else if (error.response.data instanceof ArrayBuffer) {
        const textDecoder = new TextDecoder();
        const decodedMessage = textDecoder.decode(error.response.data);
        const errorJson = JSON.parse(decodedMessage);
        text = errorJson.details;
  
      }
    }
    // } else if (typeof error === 'string') {
    //   text = error;
    // } else if (error instanceof Error) {
    //   text = error.message;
    // } else if (error.response.data instanceof ArrayBuffer) {
    //   const textDecoder = new TextDecoder();
    //   const decodedMessage = textDecoder.decode(error.response.data);
    //   const errorJson = JSON.parse(decodedMessage);
    //   text = errorJson.details;

    // } else {
    //   text = JSON.stringify(error);
    // }

    this.showError(title, text);
  }

  showError(title: string, text?: string) {
    if (this.toast) {
      this.toast.show({
        severity: 'error',
        summary: title,
        detail: text,
        life: 2000,
      });
    }
  }

  showSuccess(title: string, text?: string) {
    if (this.toast) {
      this.toast.show({
        severity: 'success',
        summary: title,
        detail: text,
        life: 2000,
      });
    }
  }
}

const errorService = new ErrorService();
export { errorService };
