import { RouteObject } from "react-router-dom";
import DashboardPage from "./dashboard-page";
import ReportsTimePage from "./reports-time/reports-time-page";
import ReportsMerchantPage from "./reports-merchant/reports-merchant-page";
import ReportsCustomerPage from "./reports-customer/reports-customer-page";
import ReportsProcessorPage from "./reports-processor/reports-processor-page";
import ReportsStatusPage from "./reports-status/reports-status-page";
import ReportsCardBrandPage from "./reports-card-brand/reports-card-brand-page";
import ReportsCommissionPage from "./reports-commission/reports-commission-page";

const dashboardRouter: RouteObject[] = [
    {
        path: '',
        element: <DashboardPage/>
    },
    {
        path: 'reports-time',
        element: <ReportsTimePage/>
    },
    {
        path: 'reports-merchant',
        element: <ReportsMerchantPage/>
    },
    {
        path: 'reports-customer',
        element: <ReportsCustomerPage/>
    }, 
    {
        path: 'reports-processor',
        element: <ReportsProcessorPage/>
    },
    {
        path: 'reports-status',
        element: <ReportsStatusPage/>
    }, 
    {
        path: 'reports-card-brand',
        element: <ReportsCardBrandPage/>
    },
    // {
    //     path: 'reports-commission',
    //     element: <ReportsCommissionPage/>
    // }
]

export default dashboardRouter;