export const formatAmount = (amount: number) => {
    if (typeof amount !== 'number') {
      return 0;
    }
  
    return new Intl.NumberFormat('us-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };
  