import dayjs, { Dayjs } from 'dayjs';

class DateService {
     getToday(): { startDate: string; endDate: string } {
        const today = dayjs().format('YYYY-MM-DD');
        return { startDate: today, endDate: today };
    }

    getYesterday(): { startDate: string; endDate: string } {
        const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
        return { startDate: yesterday, endDate: yesterday };
    }

     getThisMonth(): { startDate: string; endDate: string } {
        const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
        const endDate = dayjs().endOf('month').format('YYYY-MM-DD');
        return { startDate, endDate };
    }

    getLastMonth(): { startDate: string; endDate: string } {
        const startDate = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        const endDate = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        return { startDate, endDate };
    }

   getThisYear(): { startDate: string; endDate: string } {
        const startDate = dayjs().startOf('year').format('YYYY-MM-DD');
        const endDate = dayjs().endOf('year').format('YYYY-MM-DD');
        return { startDate, endDate };
    }

   getLastYear(): { startDate: string; endDate: string } {
        const startDate = dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
        const endDate = dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
        return { startDate, endDate };
    }

    formatDate(date: Dayjs | null )  {
        return date?.format('YYYY-MM-DD');
    } 

   formatDateForTable(date: string): string {
        const dateObj = new Date(date); 
    
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); 
        const year = dateObj.getFullYear();
    
        return `${hours}:${minutes} ${day}.${month}.${year}`;
    }
}

const dateService = new DateService;

export default dateService;
