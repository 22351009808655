import { PropsWithChildren, useState } from "react";
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import './statistics-section.scss';

interface StatisticsSectionProps extends PropsWithChildren {
    title: string;
}

const StatisticSection: React.FC<StatisticsSectionProps> = ({title,children}) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleSection = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <div className="statistics-section">
            <div className="section-header flex-row flex-align-space-between-center"  onClick={toggleSection}>
                <span className="title">{title}</span>
                <ArrowIcon
                    className={`arrow-icon ${isOpen ? "open" : ""}`}
                   
                />
            </div>
           
            <div
                className={`section-content ${isOpen ? "open" : ""}`}
            >
              {isOpen &&    children }
            </div>
        </div>
    )
}

export default StatisticSection;